@import '../variables.scss';

.imms-table {
  width: 100%;
  th {
    background: #f5f5f5;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    padding: 12px;
  }

  td {
    padding: 16px 12px;
    font-size: 16px;
    font-weight: 400;
  }

  tr:not(:nth-last-child(-n+1)) td{
    border-bottom: 1px solid $color-for-borders;
  }

  &.with-py-24px {
    td {
      padding: 24px 12px;
    }
  }

  &.fields-table {
    td:first-child {
      color: #808080;
    }
  }
}
