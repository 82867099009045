.px-32px { padding-left: 32px !important; padding-right: 32px !important;}
.py-16px { padding-top: 16px !important; padding-bottom: 16px !important;}
.py-24px { padding-top: 24px !important; padding-bottom: 24px !important;}
.py-32px { padding-top: 32px !important; padding-bottom: 32px !important;}

.w-20pct { width: 20% !important;}
.w-80pct { width: 80% !important;}

.h-34px { height: 34px !important;}

.w-162px { width: 162px !important;}
.w-336px { width: 336px !important;}
