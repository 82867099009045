//
// Required Label
//

.required {
    &:before {
        content: "*";
        position: relative;
        left: -9px;
        font-size: inherit;
        color: var(--#{$prefix}danger);
        padding-left: 0.25rem;
        font-weight: $font-weight-bold;
    }
    &-after {
      &::after {
        content: "*";
        position: relative;
        left: 0.25em;
        font-size: inherit;
        color: var(--#{$prefix}danger);
        padding-right: 0.25em;
        font-weight: $font-weight-bold;
      }
    }
}

.form-label.required {
  margin-left: -10px;
  width: max-content;
}
