
.grid-rows-5 {
  grid-template-rows: repeat(5, 1fr);
}
.grid-rows-4 {
  grid-template-rows: repeat(4, 1fr);
}
.grid-rows-3 {
  grid-template-rows: repeat(3, 1fr);
}
.grid-rows-2 {
  grid-template-rows: repeat(2, 1fr);
}
.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}
.grid-flow-column {
  grid-auto-flow: column;
}
.grid-row-span-2 {
  grid-row: span 2;
}
