
.reject-reason {
  // min-height: 92px;
  border: 1px solid #B12A0B;
  border-radius: 8px;
  padding: 20px;
  background: #FDDED8;
  margin-bottom: 24px;
}

.information-box {
  border: 1px solid #0C69B0;
  border-radius: 8px;
  padding: 20px;
  background: #D3F2FF;
  margin-bottom: 24px;
  color: #0C69B0;
}
