
div[ngbAccordionItem][ng-reflect-disabled="true"] .accordion-button::after {
  display: none;
}
.accordion-body {
  padding: 24px 12px;
}
.accordion-header, .accordion-button {
  font-weight: 600;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  background: #f5f5f5 !important;
  color: #252f4a !important;
}
.accordion-header {
  padding: 16px 32px;
}
.accordion-button {
  padding: 0;
  border: none !important;
  box-shadow: none !important;
}
div[ngbaccordion].accordion, .accordion-item {
  border-color: #e3e3e3;
}
