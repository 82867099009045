@import "_breakpoints";

.media-gallery-modal {

  @include scr-up('xl') {
    width: 1269px;
    left: -25%;
  }

  @include scr-between('mlg+1', 'xl') {
    left: -5%;
  }

  @include scr-between('md+1', 'mlg') {
    left: -33%;
  }

  @include scr-between('md+1', 'xl') {
    width: 840px;
  }

  @include scr-between('sm+1', 'md') {
    left: -12%;
    width: 623px;
  }

  @include scr-between('sm+1', 'sm') {
    left: -12%;
    width: 623px;
  }
}


.invisible-container {
  background-color: none;
}

.modal-upload.modal-content {
  width: 425px;
}
