@mixin generate-icon-class($icon-name) {
  .icon-#{$icon-name} {
    width: 16px;
    height: 20px;
    background-color: black;
    mask-image: url('/assets/icons/icon-#{$icon-name}.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    -webkit-mask-image: url('/assets/icons/icon-#{$icon-name}.svg');
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
  }
}

$icon-names: user-add, upload, renewal;

@each $icon-name in $icon-names {
  @include generate-icon-class($icon-name);
}

.app-root .menu-item .menu-link .menu-icon svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
