//
// Demo
//


.overlay  {
    position: relative;

    .overlay-layer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--#{$prefix}overlay-bg);
        transition: all 0.3s ease;
        opacity: 0;
    }

    &.overlay-show,
    &.overlay-block,
    &:hover {
        .overlay-layer {
            transition: all 0.3s ease;
            opacity: 1;
        }
    }

    &.overlay-block {
        cursor: wait;
    }
}
