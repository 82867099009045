.uppercase-text {
  text-transform: uppercase !important;
}
.lowercase-text {
  text-transform: lowercase !important;
}

.im2-title {
  color: #112;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.im2-gray-text {
  color: var(--Neutral-colors-Gray-50, var(--Neutralcolors-Gray-50, #808080));
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.whitespace-preline { white-space: pre-line; }

.text-2rem { font-size: 2rem !important;}
.text-20px { font-size: 20px !important;}
.text-40px { font-size: 40px !important;}
.text-16px { font-size: 16px !important;}

.text-fileupload-green { color: var(--utility-colors-positive, #007c38) !important; }
