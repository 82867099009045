/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import "./assets/sass/plugins.scss";
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";
@import "azure-maps-control/dist/atlas.min.css";

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
@import "./assets/plugins/keenicons/duotone/style.css";
// @import "./assets/plugins/keenicons/outline/style.css";
// @import "./assets/plugins/keenicons/solid/style.css";
@import "./assets/sass/custom/grid.scss";
@import "./assets/sass/custom/accordion.scss";
@import "./assets/sass/custom/text.scss";
@import "./assets/sass/custom/table.scss";
@import "./assets/sass/custom/modal.scss";
@import "./assets/sass/custom/common.scss";
@import "./assets/sass/spacings.scss";
@import "./assets/sass/icons/custom-icons.scss";

.select-filter {
  align-items: center;
  gap: 10px;
}

.select-content-filter {
  width: auto;
  color: #0c69b0;
}

.select-label-filter {
  font-weight: 600;
  font-size: 16px;
  width: 100%;
}
.border-gray-300 {
  border-color: var(--bs-gray-300) !important;
}
.min-w-8rem {
  min-width: 8rem;
}
.min-w-10rem {
  min-width: 10rem;
}
.min-w-12rem {
  min-width: 12rem;
}
.min-w-14rem {
  min-width: 14rem;
}
.min-w-16rem {
  min-width: 16rem;
}

.form-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}
span {
  line-height: 0;
}
.font-weight-bold {
  font-weight: bold !important;
}
.pointer:hover {
  cursor: pointer;
}
// CUSTOMIZE BOOTSTRAP BORDER CLASS
.border:not(.border-danger):not(.border-primary) {
  border-color: var(--neutral-colors-gray-15, #e3e3e3) !important;
}
.font-size-12px {
  font-size: 12px !important;
}
.lh-1point5rem {
  line-height: 1.5rem !important;
}
.flex-1 {
  flex: 1;
}
.gap-4px { gap: 4px; }
.gap-8px { gap: 8px; }
.gap-12px { gap: 12px; }
.gap-15px { gap: 15px; }
.gap-16px { gap: 16px; }
.gap-20px { gap: 20px; }
.gap-24px { gap: 24px; }
.gap-32px { gap: 32px; }
.h-0 { height: 0 !important;}
.p-16px { padding: 16px !important; }
.p-24px {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.pl-24px { padding-left: 24px !important; }
.pr-24px { padding-right: 24px !important; }
.pt-24px { padding-top: 24px !important; }
.pt-8px { padding-top: 8px !important; }
.pb-8px { padding-bottom: 8px !important; }
.pb-16px { padding-bottom: 16px !important; }
.py-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-16px {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.px-24px {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.py-24px {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.px-32px {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.pb-24px {
  padding-bottom: 24px !important;
}
.pt-24px {
  padding-top: 24px !important;
}
.pt-32px {
  padding-top: 32px !important;
}
.py-12px {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.py-14px {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.px-25px {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.ml-8px {
  margin-left: 8px;
}
.mx-32px {
  margin-left: 32px !important;
  margin-right: 32px !important;
}
.mb-24px {
  margin-bottom: 24px !important;
}
.mt-24px {
  margin-top: 24px !important;
}
.mb-32px {
  margin-bottom: 32px !important;
}
.mt-32px {
  margin-top: 32px !important;
}
.my-24px {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.text-primary {
  color: var(--applicationcolors-blue, #0c69b0);
}
.btn-primary {
  border: 1px solid var(--bs-primary) !important;
}
.btn {
  width: fit-content;
  font-size: var(--bs-body-font-size);
  &:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon):not(.btn-sm) {
    padding: 12px 24px;
  }
}

.btn-back-page {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
  margin-bottom: 32px;
  margin-left: 30px;
  padding: 0px !important;
}


.lh-0 {
  line-height: 0;
}
.table-layout-fixed {
  table-layout: fixed;
}
.app-header {
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 10px -2px rgba(16, 24, 40, 0.05) !important;
}
.app-sidebar {
  border-right: 1px solid var(--neutralcolors-gray-10, #e5e5e5) !important;
  .menu-link.active {
    background-color: var(--applicationcolors-bluelight, #e1efff) !important;
  }
}
.app-footer {
  border-top: 1px solid var(--neutral-colors-gray-10, #e5e5e5) !important;
  background-color: transparent !important;
  margin-left: 30px;
  margin-right: 30px;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

// FORM STYLING
.form-control.is-forbidden {
  background-image: url(./assets/icons/Content/icon-med-ban.svg);
  background-repeat: no-repeat;
  background-position: bottom calc(0.375em + 0.3875em) right calc(0.375em + 0.3875em);
  background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
  background-color: var(--bs-gray-200) !important;
  cursor: not-allowed;
}
input[type="date"] {
  background-image: url(./assets/icons/Content/icon-med-calendar.svg);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3875rem) center;
  background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
  position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.form-check-input {
  height: var(--bs-body-font-size);
  width: var(--bs-body-font-size);
}
.form-check-input[type="checkbox"] {
  border-radius: 3px;
}
.form-control, .form-select  {
  font-size: var(--bs-body-font-size);
}
.form-control:not(.form-select,.is-forbidden, input[type="date"]) {
  padding: 0.775rem 1rem 0.775rem 1rem;
}
input[type="date"], .form-control.form-select, .form-control.is-forbidden {
  padding: 0.775rem 3rem 0.775rem 1rem;
}
input[readonly]:not(input[type="checkbox"]), textarea[readonly] {
  background-color: var(--neutralcolors-gray-04, #f5f5f5) !important;
}
input[readonly]:not(.pointer):not(input[type="checkbox"]), textarea[readonly]:not(.pointer) {
  cursor: not-allowed;
}
// .ng-invalid.ng-touched {
//   border-color: var(--bs-danger);
// }
// FORM STYLING

// TABLE STYLING
.exxon-im2-table {
  &.table:not(.table-bordered) {
    thead {
      th {
        font-size: 12px;
        background-color: var(--neutralcolors-gray-04, #f5f5f5);
        &.table-sort-asc, &.table-sort-desc {
          display: table-cell;
          align-items: center;
        }
        &.table-sort-desc::after {
          -webkit-mask-image: url(./assets/icons/Content/icon-bi-arrow-down.svg);
          mask-image: url(./assets/icons/Content/icon-bi-arrow-down.svg);
        }
        &.table-sort-asc::after {
          -webkit-mask-image: url(./assets/icons/Content/icon-bi-arrow-up.svg);
          mask-image: url(./assets/icons/Content/icon-bi-arrow-up.svg);
        }
      }
    }
    tr {
      th,
      td {
        border-left: 0;
        border-right: 0;
        border-color: #e3e3e3;
      }
      td {
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }
  }
}
.exxon-im2-table.table:not(.table-bordered) tr:first-child,
.exxon-im2-table.table:not(.table-bordered) th:first-child,
.exxon-im2-table.table:not(.table-bordered) td:first-child {
  padding-left: 12px;
}
.exxon-im2-table.table:not(.table-bordered) tr:last-child,
.exxon-im2-table.table:not(.table-bordered) th:last-child,
.exxon-im2-table.table:not(.table-bordered) td:last-child {
  padding-right: 12px;
}
.badge-loading {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  width: 100%;
  height: calc(1em + 4px);
  border-radius: 8px;
  vertical-align: middle;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
.whitespace-nowrap {
  white-space: nowrap;
}
// TABLE STYLING
.incorrect {
  &::before {
    content: url("./assets/icons/Content/warning-circle.svg");
    padding-right: 10px; /* adds some spacing after the icon */
  }
  width: max-content;
  line-height: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.correct {
  &::before {
    content: url("./assets/icons/Content/check-circle.svg");
    padding-right: 10px; /* adds some spacing after the icon */
  }
  width: max-content;
  line-height: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text-success {
  color: #4c9f13;
}
.text-danger {
  color: #b12a0b;
}
.underscored-text {
  text-decoration: underline;
}
.text-24px {
  font-size: 24px;
}
.shadow-0 {
  box-shadow: none !important;
}
.w-min-content { width: min-content !important;}
.w-max-content { width: max-content !important;}
.w-fit-content { width: fit-content !important;}

.h-min-content { height: min-content !important;}
.h-max-content { height: max-content !important;}
.h-fit-content { height: fit-content !important;}

.pointer-events-none { pointer-events: none !important;}

.info-panel-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.hide-when-empty:empty {
  display: none;
}

.stickyConfirmation {
  position: sticky;
  z-index: 10;
  top: 85px;
  background-color: white;
  padding: 20px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.page-desc {
  font-size: calc(1rem + 4px) !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-none {
 text-decoration: none;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.invalid-feedback {
  display: block;
}