//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
	.app-content {
        padding-bottom: $app-content-padding-y;
        padding-left: $app-content-padding-x;
        padding-right: $app-content-padding-x;
	}

  .app-content-wrapper {
    padding-top: $app-content-padding-y;
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.app-content {
		max-width: none;
        padding-bottom: $app-content-padding-y-mobile;
        padding-left: $app-content-padding-x-mobile;
        padding-right: $app-content-padding-x-mobile;
	}

  .app-content-wrapper {
		padding-top: $app-content-padding-y-mobile;
  }
}
